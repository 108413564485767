<template>
  <b-form-group
      :label="label"
      :label-for="name"
  >
    <validation-provider
        v-slot="{ errors }"
        :name="label"
        :vid="name"
        :rules="rules"
    >
      <b-form-file
          :id="name"
          v-model="inputValue"
          :placeholder="placeholder"
          :accept="accept"
          :readonly="readonly"
          :state="errors.length > 0 ? false:null"
          :disabled="disabled"
          browse-text="Procurar"
      />
      <small class="text-danger">{{ errors[0] ?? '' }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

import {BFormFile, BFormGroup} from 'bootstrap-vue'
import {ValidationProvider} from 'vee-validate'
import {required} from '@validations'

export default {
  name: 'I2FormInputFile',
  components: {
    BFormGroup,
    BFormFile,
    ValidationProvider,
  },
  props: {
    value: {
      type: [File, Array, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '*',
    },
    type: {
      type: String,
      default: 'text',
    },
  },

  data: () => ({
    inputValue: null,
    errors: [],
    required,
  }),
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style scoped>

</style>
