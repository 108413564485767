const patient = [
  {
    path: '/patients',
    name: 'patients.list',
    component: () => import('@/modules/patient/views/PatientList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', , 'analyst'],
    },
  },
  {
    path: '/home/patient',
    name: 'home.patient',
    component: () => import('@/modules/patient/components/HomePatient.vue'),
    meta: {
      roles: ['user'],
    },
  },
  {
    path: '/scheduling/patient',
    name: 'scheduling.patient',
    component: () => import('@/modules/appointment/components/SpecialtyScheduling.vue'),
  },
  {
    path: '/schedule/patient',
    name: 'schedule.patient',
    component: () => import('@/modules/appointment/components/Schedule.vue'),
    props: true,
  },
  {
    path: '/appointments/patient',
    name: 'appointments.patient',
    component: () => import('@/modules/appointment/views/AppointmentList.vue'),
    meta: {
      roles: ['user'],
    },
  },
]

export default patient
