const appointment = [
  {
    path: '/appointments',
    name: 'appointments.list',
    component: () => import('@/modules/appointment/views/AppointmentList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'user', 'analyst'],
    },
  },
  {
    path: '/patient/appointment',
    name: 'patient.scheduling',
    component: () => import('@/modules/appointment/components/SpecialtyScheduling.vue'),
    meta: {
      layout: 'full',
      roles: ['user'],
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/modules/appointment/components/Payment.vue'),
    meta: {
      roles: ['user'],
    },
  },
  {
    path: '/payment/:uuid',
    name: 'payment.confirm',
    props: true,
    component: () => import('@/modules/appointment/components/Payment.vue'),
    meta: {
      roles: ['user'],
    },
  },
]

export default appointment
