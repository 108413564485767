const state = {
    clientData: null,
}

const getters = {

}

const mutations = {
    // eslint-disable-next-line no-shadow
    SET_CLIENT_DATA(state, payload) {
        state.clientData = payload
    },
}

const actions = {
    setClientData: async ({ commit }, data) => {
        commit('SET_CLIENT_DATA', data)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
