const unity = [
  {
    path: '/unities',
    name: 'unities.list',
    component: () => import('@/modules/unity/views/UnityList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },
  },
]

export default unity
