import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Echo from 'laravel-echo'
// eslint-disable-next-line import/no-extraneous-dependencies
window.Pusher = require('pusher-js')

class Ws {
    echo = null

    isConnected = false

    connect() {
        if (this.isConnected) {
            return
        }
        this.echo = new Echo({
            broadcaster: 'reverb',
            key: process.env.VUE_APP_SOCKET_APP_KEY,
            secret: process.env.VUE_APP_SOCKET_APP_SECRET,
            forceTLS: false,
            cluster: 'mt1',
            wsHost: process.env.VUE_APP_SOCKET_URL,
            wsPort: process.env.VUE_APP_SOCKET_PORT,
            disableStats: true,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                },
            },
        })
        this.isConnected = true
    }

    channel(channel) {
        this.connect();
        return this.echo.channel(channel)
    }

    leave(channel) {
        this.connect();
        return this.echo.leave(channel)
    }

    send(event, message, channel = null) {
        this.connect();
        this.echo.connector.pusher.send_event(event, message, channel)
    }
}

const socket = new Ws()
window.ws = socket
Vue.prototype.$echo = socket
