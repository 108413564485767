<template>
    <span/>
</template>
<script>
export default {
    name: 'Hotkeys', // data
    props: {
        shortcuts: {
            type: Array,
            required: true,
        },
        debug: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data: () => ({
        supportedShortcuts: [],
    }),
    created() {
        if (this.shortcuts && this.shortcuts.length) {
            this.shortcuts.forEach(sc => {
                if (sc) {
                    if (sc === 'ArrowLeft') {
                        this.supportedShortcuts.push({ keyString: sc, keyCode: 37 })
                    } else if (sc === 'ArrowRight') {
                        this.supportedShortcuts.push({
                            keyString: sc,
                            keyCode: 39,
                        })
                        this.supportedShortcuts.push({
                            keyString: sc,
                            keyCode: 38,
                        })
                        this.supportedShortcuts.push({
                            keyString: sc,
                            keyCode: 40,
                        })
                    } else if (sc === 'Enter') {
                        this.supportedShortcuts.push({
                            keyString: sc,
                            keyCode: 13,
                        })
                    } else {
                        this.supportedShortcuts.push({
                            keyString: sc,
                            keyCode: sc.charCodeAt(0),
                        })
                    }
                }
            })
        }
    },
    mounted() {
        document.addEventListener('keydown', this.onUseKeyboardShortcuts, false)
        if (this.debug) {
            console.log('Vue Keyboard Shortcuts ENABLED')
        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onUseKeyboardShortcuts)
        if (this.debug) {
            console.log('Vue Keyboard Shortcuts DISABLED ')
        }
    }, // mounted

    beforeUnmount() {
        if (this.debug) {
            console.log('Vue Keyboard Shortcuts DISABLED')
        }
        document.removeEventListener('keydown', this.onUseKeyboardShortcuts)
    },
    methods: {

        onUseKeyboardShortcuts(evt) {
            if (!this.enabled) {
                return
            }
            const key = evt.keyCode || evt.which
            const ctr = window.navigator.platform.match('Mac') ? evt.metaKey : evt.ctrlKey
            const isKeySupported = this.supportedShortcuts.some(({ keyCode }) => keyCode === key)
            if (ctr /* CTRL | CMD */ && isKeySupported) {
                evt.preventDefault()
                const { keyString } = this.supportedShortcuts.find(({ keyCode }) => keyCode === key)
                if (this.debug) {
                    console.log(`CMD (CTRL) + ${keyString} (${key}) pressed`)
                }
                this.$emit('triggered', { key, keyString })
                evt.preventDefault()
            }
        },
    },
}
</script>
