import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import typeIndex from './types/type_index'
import auth from './auth/auth'
import verticalMenu from './vertical-menu'
import model from './model'
import timezones from './timezones/timezones'
import client from './client'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        loader,
        auth,
        typeIndex,
        model,
        timezones,
        client
    },
    strict: process.env.DEV,
})
