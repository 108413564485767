import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, userRole } from '@/auth/utils'
import appointment from '@/modules/appointment/routes/appointment'
import clients from '@/modules/clients/routes/clients'
import password from '@/modules/auth/routes/password'
import patient from '@/modules/patient/routes/patient'
import professional from '@/modules/professional/routes/professional'
import operator from '@/modules/operator/routes/operator'
import unity from '@/modules/unity/routes/unity'
import users from '@/modules/users/routes/users'
import specialty from '@/modules/specialty/routes/specialty'
import timeappointments from '@/modules/timeappointments/routes/timeappointments'
import statusappointments from '@/modules/statusappointments/routes/statusappointments'
import videoroom from '@/modules/videoroom/routes/videoroom'
import satisfactionsurvey from '@/modules/satisfactionsurvey/routes/satisfactionsurvey'
import Paymentmethod from '@/modules/paymentmethod/routes/paymentmethod'
        // :routes-imports://

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login/patient',
    name: 'login.patient',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/login/appointment',
    name: 'login.appointment',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/login/professional',
    name: 'login.professional',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
  {
    path: '/agendamento',
    name: 'scheduling',
    component: () => import('@/modules/appointment/components/SpecialtyScheduling.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/appointment/schedule',
    name: 'schedule',
    component: () => import('@/modules/appointment/components/Schedule.vue'),
    props: true,
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/register/patient',
    name: 'register.patient',
    component: () => import('@/modules/appointment/components/Register.vue'),
    props: true,
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/register/appointment',
    name: 'register.appointment',
    component: () => import('@/modules/appointment/components/Register.vue'),
    props: true,
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/register/professional',
    name: 'register.professional',
    component: () => import('@/modules/professional/components/Register.vue'),
    props: true,
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/error/views/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...patient,
    ...professional,
    ...unity,
    ...appointment,
    ...operator,
    ...specialty,
    ...timeappointments,
    ...statusappointments,
    ...videoroom,
    ...satisfactionsurvey,
    ...Paymentmethod,
// :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (
    to.meta.public
    || to.name === 'error-404'
    || to.name === 'login'
    || to.name === 'login/patient'
    || to.name === 'login/appointment'
    || to.name === 'login/professioanl'
    || to.name === 'perdi-minha-senha'
    || to.name === 'recuperar-senha'
    || to.name === 'register/patient'
    || to.name === 'register/appointment'
    || to.name === 'register/professional'
    || to.name === 'scheduling'
  ) {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('/')
      return false
    }
  }

  const roles = to.meta.roles || []

  if (roles.length > 0 && !roles.find(item => item === userRole())) {
    router.push({ name: 'error-404' })
    return false
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
