<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h6>{{ title }}</h6>
      <input
          type="range"
          min="0"
          max="10"
          v-model="rating"
          @input="updateColor"
          class="rating-slider"
      />
      <div :style="{ color: ratingColor }" class="rating-display">
        Nota: {{ rating }}
      </div>
      <textarea
          v-model="description"
          placeholder="Em poucas palavras, descreva o que motivou a sua nota (opcional)"
      ></textarea>
      <div class="modal-buttons">
        <button @click="submitRating">Enviar</button>
        <button @click="closeModal">Cancelar</button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'DsRating',
  data() {
    return {
      show: true,  // Controla a exibição do modal
      rating: 5,   // Valor inicial da nota
      title:'Considerando a experiência da sua consulta o quanto você recomendaria para um amigo ou familiar?',
      description: '',
      ratingColor: '#808080', // Cor inicial neutra (cinza)
    };
  },
  props: {
    appointment_uuid: {
      type: String,
    },
  },
  methods: {
    updateColor() {
      const red = (255 * (10 - this.rating)) / 10;
      const green = (255 * this.rating) / 10;
      this.ratingColor = `rgb(${red}, ${green}, 0)`;
    },
    async submitRating() {

      console.log(this.appointment_uuid)
      const data = {}
      let response = {}

      data.score = this.rating
      data.reviews = this.description
      if (this.$route.query.uuid){
        data.appointment_uuid = this.$route.query.uuid
      } else {
        data.appointment_uuid = this.appointment_uuid
      }

      response = await this.$http.post('satisfaction-surveys', data)

      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Nota enviada com sucesso.')

      this.closeModal();
    },
    closeModal() {
      this.show = false;
      if (this.$route.query.uuid){
        this.$router.push({
          query: {
            ...this.$route.query,
            uuid: undefined,
          },
        }).catch(() => {})
      }
      this.rating = 5
      this.description = ''
      this.$emit('refresh', true)
    },
  },
  mounted() {
    this.updateColor();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 530px;
}

.rating-slider {
  width: 100%;
  margin: 20px 0;
}

.rating-display {
  font-size: 1.5em;
  margin: 10px 0;
}

textarea {
  width: 100%;
  height: 60px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:first-child {
  background-color: #4CAF50; /* Verde */
  color: white;
}

button:last-child {
  background-color: #f44336; /* Vermelho */
  color: white;
}
</style>