const statusAppointments = [
  {
    path: '/status-appointments',
    name: 'status-appointments.list',
    component: () => import('@/modules/statusappointments/views/StatusAppointmentsList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
]

export default statusAppointments
