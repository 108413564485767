const timeAppointments = [
  {
    path: '/time-appointments',
    name: 'time-appointments.list',
    component: () => import('@/modules/timeappointments/views/TimeAppointmentsList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },
  },
  {
    path: '/duty-list/:uuid',
    name: 'duty.list',
    props: true,
    component: () => import('@/modules/timeappointments/components/DutyList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },
  },
  {
    path: '/intervals/:uuid',
    name: 'intervals.list',
    props: true,
    component: () => import('@/modules/timeappointments/components/IntervalsList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },
  },
]

export default timeAppointments
