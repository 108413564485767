const state = {
  types_index: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_TYPE_INDEXES(state, payload) {
    state.types_index = payload
  },
}

const actions = {
  indexes: async ({ commit }) => {
    try {
      const data = await window.http.get('types-index')
      commit('SET_TYPE_INDEXES', data)
      console.log((data))
      return data
    } catch (error) {
      return error
    }
  },
  setIndexes: async ({ commit }, index) => {
    commit('SET_TYPE_INDEXES', index)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
