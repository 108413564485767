const professional = [
  {
    path: '/professionals',
    name: 'professionals.list',
    component: () => import('@/modules/professional/views/ProfessionalList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
  {
    path: '/home/professional',
    name: 'home.professional',
    component: () => import('@/modules/professional/components/HomeProfessional.vue'),
    meta: {
      roles: ['doctor'],
    },
  },
]

export default professional
