const password = [
  {
    path: '/perdi-minha-senha',
    name: 'perdi-minha-senha',
    component: () => import('@/modules/auth/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/recuperar-senha/:token',
    name: 'recuperar-senha',
    component: () => import('@/modules/auth/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
]

export default password
