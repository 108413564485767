const paymentMethod = [
  {
    path: '/payment-methods',
    name: 'payment-methods.list',
    component: () => import('@/modules/paymentmethod/views/PaymentMethodList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default paymentMethod
