<template>
  <b-row>
    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.cep"
        label="CEP"
        mask="#####-###"
        placeholder="Ex: 00000-000"
        name="cep"
        @input="getInfoCep"
      />
    </b-col>
    <b-col
      sm="12"
      md="12"
    >
      <i2-form-input
        v-model="model.logradouro"
        rules="required"
        label="Logradouro"
        name="logradouro"
      />
    </b-col>
    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.numero"
        label="Numero"
        name="numero"
      />
    </b-col>
    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.complemento"
        label="Complemento"
        name="complemento"
      />
    </b-col>
    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.bairro"
        label="Bairro"
        name="Bairro"
      />
    </b-col>
    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.localidade"
        rules="required"
        label="Cidade"
        name="localidade"
      />
    </b-col>

    <b-col
      sm="6"
      md="6"
    >
      <i2-form-input
        v-model="model.uf"
        rules="required"
        label="UF"
        name="uf"
        mask="##"
      />
    </b-col>

  </b-row>
</template>
<script>
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'

export default {
  name: 'Address',
  components: { I2FormInput },
  props: {
    address: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBlock: false,
      states: [],
      model: {
        cep: '',
        logradouro: '',
        complemento: '',
        bairro: '',
        localidade: '',
        numero: '',
        uf: '',
      },
    }
  },
  watch: {
    model() {
      this.$emit('input', this.preparedData(this.model))
    },
    address(value) {
      if (!value) {
        return
      }
      this.isBlock = false
      if (value.cep && value.cep !== '') {
        this.isBlock = true
      }
      this.model = value
    },
  },
  async mounted() {
    if (this.address) {
      this.model = { ...this.address }
    }
  },
  methods: {
    clearForm() {
      this.isBlock = false
      this.model = {
        cep: '',
        logradouro: '',
        complemento: '',
        bairro: '',
        localidade: '',
        numero: '',
        uf: '',
      }
    },
    preparedData(value) {
      return { address: value }
    },
    async getInfoCep() {
      const { cep } = this.model
      if (cep && cep.length === 9) {
        const searchCep = cep.replace('-', '')
        fetch(`https://viacep.com.br/ws/${searchCep}/json/`)
          .then(response => response.json())
          .then(data => {
            this.model = data
          })
          .catch(err => {
            console.log('Cep Nao encontrado', err)
          })
      }
    },
  },
}
</script>

<style scoped>
</style>
