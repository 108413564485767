<template>
  <b-container class="p-0 pt-1 pb-3">
    <b-row v-if="showSearch" class="align-items-center mb-1">
      <b-col class="text-right" md="9" sm="9" xs="9">
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Digite aqui para pesquisar"
          />
          <b-input-group-append>
            <b-button size="sm" @click="onClear">
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="showSearch" md="9">
        <slot name="search" />
      </b-col>
      <b-col
        :md="3"
        :sm="3"
        :xs="3"
        class="align-items-end"
        style="text-align: right"
      >{{ totalRows }} Registros</b-col>
    </b-row>
    <b-row v-if="isButtonAdd" align-h="end" class="mb-1 justify-content-md-end">
      <b-col cols="12" md="auto">
        <b-button variant="primary" size="sm" @click="cadastrarConsulta">
          <FeatherIcon icon="PlusIcon" />
          &nbsp;<span class="text-nowrap">Adicionar nova consulta</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card v-for="item in itemList" :key="item['uuid']" class="p-1" no-body>
      <b-card-body style="padding: 5px;">
        <component :is="name" :data="item" @presencial="$emit('onChangePage', true)" @refresh="refresh"/>
      </b-card-body>
    </b-card>
    <!-- Mensagem de carregamento -->
    <div v-if="loading" class="text-center mb-3">
      <span class="p-1 bg-secondary text-white">
        <span class="fa fa-spinner fa-spin" />Carregando...</span>
    </div>
    <!-- Mensagem de fim da lista -->
    <div v-if="endOfList" class="text-center mb-3">
      <feather-icon icon="ArrowUpCircleIcon" size="40" @click="scrollToTop()"/>
    </div>
    <b-row v-if="showPagination" class="pl-1 pr-1 pt-0">
      <b-col md="6" sm="6" xs="6">
        <b-form-group class="mb-0" style="text-align: left">
          <label class="d-inline-block text-sm-left pr-1" for="perPageSelect">Por página:
          </label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-25"
            @change="onPerPageChange"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" sm="6" xs="6" style="text-align: right">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          @change="handlePageChange"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'
import DsPatientGridCard from './DsPatientGridCard.vue'
import DsProfessionalGridCard from './DsProfessionalGridCard.vue'

export default {
  name: 'DsGridCard',
  components: {
    BCard,
    BCardBody,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    DsPatientGridCard,
    DsProfessionalGridCard,
  },
  props: {
    fields: {
      type: [Array],
      required: true,
    },
    items: {
      type: [Array, Object],
      required: true,
    },
    newButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
    editButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
      required: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    perPage: 5,
    pageOptions: [10, 25, 50, 75, 100],
    totalRows: 0,
    currentPage: 1,
    filter: null,
    itemList: [],
    breakPoint: '',
    filterOn: [],
    isServerData: false,
    isButtonAdd: false,
    name: '',
    page: 2, // Número da página inicial
    loading: false, // Flag para controlar se está carregando
    endOfList: false,
    per_page: 25, // Número de dados por página
  }),
  computed: {
    ...mapState(['app/windowWidth']),
  },
  watch: {
    items(data) {
      this.itemList = data.data || data
      let totalRows = this.itemList.length || 0
      if (data.meta) {
        this.isServerData = true
        totalRows = data.meta.total
        this.itemList = data.data
        this.currentPage = data.meta.current_page
      }
      this.totalRows = totalRows
      setTimeout(() => {
        this.$forceUpdate()
      }, 300)
      this.breakPoint = this.currentBreakPoint()
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
    this.userData = JSON.parse(sessionStorage.getItem('userData'))
    if (this.userData.role === 'user') {
      this.name = 'DsPatientGridCard'
      this.isButtonAdd = true
    } else if (this.userData.role === 'doctor') {
      this.name = 'DsProfessionalGridCard'
    }
  },
  beforeDestroy() {
    // Remove o evento de scroll ao destruir o componente
    window.removeEventListener('scroll', this.onScroll)
  },
  updated() {},
  methods: {
    ...mapGetters('app', ['currentBreakPoint']),
    scrollToTop() {
      // Rolar suavemente para o topo
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    cadastrarConsulta() {
      this.$root.$emit('titleChanged', 'Cadastro Consulta')
      this.$router.push({ name: 'scheduling.patient' }).catch(() => {})
    },
    async getData() {
      this.loading = true
      this.$http
        .get(
          `appointments?page=${this.page}&per_page=${this.per_page}`,
        )
        .then(response => {
          const newItems = response.data
          if (newItems && newItems.length > 0) {
            this.itemList = this.itemList.concat(newItems)
            this.page++
          } else {
            this.endOfList = true // Não há mais itens para carregar
          }
        })
        .finally(() => {
          this.loading = false
        })
      this.$emit('onChangePage', true)
    },
    // Método chamado quando ocorre scroll
    onScroll() {
      const { scrollY } = window
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight
      const offset = 100 // Espaço para o carregamento iniciar antes de chegar no fim da página

      if (
        scrollY + windowHeight >= documentHeight - offset
        && !this.loading
        && !this.endOfList
      ) {
        this.getData()
      }
    },
    handlePageChange(page) {
      const event = {
        page,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onPerPageChange() {
      const event = {
        page: this.currentPage,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onClear() {
      this.filter = ''
      this.$emit('onClear')
    },
    refresh() {
      this.getData()
      this.$emit('onChangePage', true)
    }
  },
}
</script>
<style>
th.w-5 {
  width: 5%;
}

th.w-10 {
  width: 10%;
  text-wrap: none;
}
th.w-15 {
  width: 15%;
  text-wrap: none;
}
th.w-20 {
  width: 20%;
}
th.w-25 {
  width: 25%;
}
th.w-30 {
  width: 30%;
}
th.w-35 {
  width: 35%;
}
th.w-40 {
  width: 40%;
}
th.w-45 {
  width: 45%;
}
th.w-50 {
  width: 50%;
}
th.w-55 {
  width: 55%;
}
th.w-60 {
  width: 60%;
}
th.w-65 {
  width: 65%;
}
th.w-70 {
  width: 70%;
}
th.w-75 {
  width: 75%;
}
th.w-80 {
  width: 80%;
}
th.w-85 {
  width: 85%;
}
th.w-90 {
  width: 90%;
}
th.w-95 {
  width: 95%;
}
.no-border {
  border: 0px !important;
}
.table thead th,
.table tfoot th {
  vertical-align: top;
  text-transform: capitalize !important;
}
.table tr {
  cursor: default !important;
}
table td.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
</style>
