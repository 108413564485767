<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="primary"
    type="button"
    class="mr-2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <feather-icon icon="SaveIcon" class="mr-50" />
    Salvar
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ButtonSave',
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
}
</script>

<style>

</style>
